require('bootstrap');
// require('bootstrap/dist/css/bootstrap.min.css')
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';

import Swal from '/admin/plugins/sweetalert2/sweetalert2.js';

function swalNotificationMessage(icon, title, text, position = null, showConfirmBtn = null, confirmBtnText = null, toast = null, timer = null) {
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        position: position,
        showConfirmButton: showConfirmBtn,
        confirmButtonText: confirmBtnText,
        toast: toast,
        timer: timer
    });
}


$(document).on('change', '.select-vessel', function (event) {

    let vessel_id = $(this).val();

    $.ajax({
        url: $(this).data('url'),
        type: "GET",
        dataType: 'json',
        data: {
            vessel: vessel_id,
        },
        success: function (response) {
            if (response.success === true) {
                location.reload();
                swalNotificationMessage('success', 'Success', response.message, null, false, false, null, 2000);
            } else {
                swalNotificationMessage('error', 'Error', response.message, null, false, false, null, 2000);
            }
        },
    });

});



$(document).on('change', '.select-season', function (event) {

    let season_id = $(this).val();

    $.ajax({
        url: $(this).data('url'),
        type: "GET",
        dataType: 'json',
        data: {
            season: season_id,
        },
        success: function (response) {
            if (response.success === true) {
                location.reload();
                swalNotificationMessage('success', 'Success', response.message, null, false, false, null, 2000);
            } else {
                swalNotificationMessage('error', 'Error', response.message, null, false, false, null, 2000);
            }
        },
    });

});
$(".update-order-item").click(function () {
    let item_id = $(this).data('item-id');
    let order_id = $('#order_id').val();
    let is_disputed = $('#is-disputed').val();
    let reason = $(this).parent().parent().find('#reason').val();
    let url = $(this).data('url');
    $.ajax({
        url: url,
        type: 'POST',
        data: {
            order_id: order_id,
            item_id: item_id,
            is_disputed: is_disputed,
            reason: reason
        },
        success: function (response) {
            if (response.success === true) {
                swalNotificationMessage('success', 'Success', response.message, null, false, false, null, 2000);
            }
        },
    });
});

//Update order from reset button in index blade.
$('.order-reset').click(function () {
    let orderId = $(this).data('reset-order-id');
    let url = $(this).data('url');
    let data = {
        orderId: orderId
    }
    Swal.fire({
        title: 'Do you want to reset order?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.isConfirmed) {
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                success: function (response) {
                    if (response.success === true) {
                        swalNotificationMessage('success', 'Success', response.message, 'center', false, null, true, 1500);
                    } else {
                        swalNotificationMessage('error', 'Error', response.message, 'center', false, null, true, 5000);
                    }
                }
            });
        }
    })
});

let options = {
    alwaysShowResizeHandle: 'mobile' // true if we're on mobile devices
};

GridStack.init({
    resizable: {
        handles: 'e,se,s,sw,w'
    }
});
